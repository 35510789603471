import React, { useState, useEffect, useRef } from "react";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

import img1 from './../../../assets/img/02-2025/1_0.png';
import img2 from './../../../assets/img/02-2025/2_0.png';
import img3 from './../../../assets/img/02-2025/3_0.png';
import img4 from './../../../assets/img/02-2025/4_0.png';
import img5 from './../../../assets/img/02-2025/5_0.png';
import img6 from './../../../assets/img/02-2025/6_0.png';
import img7 from './../../../assets/img/02-2025/7_0.png';
import img8 from './../../../assets/img/02-2025/8_0.png';
import imgBanner from './../../../assets/img/02-2025/banner.jpg';

import Modalgallery from "./../../../components/modalSrc";

import g1 from './../../../assets/img/02-2025/gallery/1.jpg';
import g2 from './../../../assets/img/02-2025/gallery/2.jpg';
import g3 from './../../../assets/img/02-2025/gallery/3.jpg';
import g4 from './../../../assets/img/02-2025/gallery/4.jpg';
import g5 from './../../../assets/img/02-2025/gallery/5.jpg';
import g6 from './../../../assets/img/02-2025/gallery/6.jpg';
import g7 from './../../../assets/img/02-2025/gallery/7.jpg';
import g8 from './../../../assets/img/02-2025/gallery/8.jpg';
import g9 from './../../../assets/img/02-2025/gallery/9.jpg';
import g10 from './../../../assets/img/02-2025/gallery/10.jpg';
import g11 from './../../../assets/img/02-2025/gallery/11.jpg';
import g12 from './../../../assets/img/02-2025/gallery/12.jpg';
import g13 from './../../../assets/img/02-2025/gallery/13.jpg';
import g14 from './../../../assets/img/02-2025/gallery/14.jpg';
import g15 from './../../../assets/img/02-2025/gallery/15.jpg';
import g16 from './../../../assets/img/02-2025/gallery/16.jpg';
import g17 from './../../../assets/img/02-2025/gallery/17.jpg';
import g18 from './../../../assets/img/02-2025/gallery/18.jpg';
import g19 from './../../../assets/img/02-2025/gallery/19.jpg';
import g20 from './../../../assets/img/02-2025/gallery/20.jpg';
import g21 from './../../../assets/img/02-2025/gallery/21.jpg';
import g22 from './../../../assets/img/02-2025/gallery/22.jpg';
import g23 from './../../../assets/img/02-2025/gallery/23.jpg';
import g24 from './../../../assets/img/02-2025/gallery/24.jpg';
import g25 from './../../../assets/img/02-2025/gallery/25.jpg';
import g26 from './../../../assets/img/02-2025/gallery/26.jpg';
import g27 from './../../../assets/img/02-2025/gallery/27.jpg';
import g28 from './../../../assets/img/02-2025/gallery/28.jpg';
import g29 from './../../../assets/img/02-2025/gallery/29.jpg';
import g30 from './../../../assets/img/02-2025/gallery/30.jpg';
import g31 from './../../../assets/img/02-2025/gallery/31.jpg';
import g32 from './../../../assets/img/02-2025/gallery/32.jpg';
import g33 from './../../../assets/img/02-2025/gallery/33.jpg';
import g34 from './../../../assets/img/02-2025/gallery/34.jpg';
import g35 from './../../../assets/img/02-2025/gallery/35.jpg';
import g36 from './../../../assets/img/02-2025/gallery/36.jpg';
import g37 from './../../../assets/img/02-2025/gallery/37.jpg';
import g38 from './../../../assets/img/02-2025/gallery/38.jpg';
import g39 from './../../../assets/img/02-2025/gallery/39.jpg';
import g40 from './../../../assets/img/02-2025/gallery/40.jpg';
import g41 from './../../../assets/img/02-2025/gallery/41.jpg';
import g42 from './../../../assets/img/02-2025/gallery/42.jpg';
import g43 from './../../../assets/img/02-2025/gallery/43.jpg';
import g44 from './../../../assets/img/02-2025/gallery/44.jpg';
import g45 from './../../../assets/img/02-2025/gallery/45.jpg';
import g46 from './../../../assets/img/02-2025/gallery/46.jpg';
import g47 from './../../../assets/img/02-2025/gallery/47.jpg';
import g48 from './../../../assets/img/02-2025/gallery/48.jpg';
import sornUp from './../../../assets/img/icon/sorn-up.svg';

const gel = [
    g1,g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12, g13, g14, g15, g16, g17, g18, g19, g20,g21,g22,g23,g24,g25,g26,g27,g28,g29,g30,g31,g32,g33,g34,g35,g36,g37,g38,g39,g40,g41,g42,g43,g44,g45,g46,g47,g48
]

const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});

    const [dataS, setDataS] = useState(null);

    const [modalgallery, setModalgallery] = useState({ view: false });

    const [mainData, setMainData] = useState({ photo: gel });

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "ed")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    // console.log(mainData.photo[0])

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="capacity" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-md-4">
                                    <div className="font-60 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="text-justify font-18 content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <table className="w-100 table table-borderless align-self-start mb-0">
                                            <tr>
                                                <td className="align-top">
                                                    <a href="#" className="" style={{ textDecoration: " none", color: "unset" }}>
                                                        <div className="text-center">
                                                            <h4 className="weight-700 h4-respon" style={{ marginBottom: "-20px" }}>SEP-ED</h4>
                                                            <img src={img1} className="w-75 py-5" />
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        <img src={img4} className="w-50" />
                                                    </div>
                                                </td>
                                                <td className="align-top">
                                                    <a href="https://seameoseps.org/activities/capacitySep4school" className="" target={"_blank"} style={{ textDecoration: " none", color: "unset" }}>
                                                        <div className="text-center">
                                                            <h4 className="weight-700 h4-respon" style={{ marginBottom: "-25px" }}>SEP4School</h4>
                                                            <img src={img2} className="w-100 pt-3 pt-md-0" />
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        <img src={img4} className="w-50" />
                                                    </div>
                                                </td>
                                                <td className="align-top">
                                                    <a href="https://seameoseps.org/activities/capacitySepRegionalYouthCamp" className="" target={"_blank"} style={{ textDecoration: " none", color: "unset" }}>
                                                        <div className="text-center">
                                                            <h4 className="weight-700 h4-respon" style={{ marginBottom: "-5px" }}>SEP Youth Camp</h4>
                                                            <img src={img3} className="w-100" />
                                                        </div>
                                                    </a>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center py-3 py-md-5 text-start" style={{ background: "#FFDE59" }}>
                                <div className="col-11 col-md-9 col-lg-9">
                                    <div>
                                        <div><img src={imgBanner} className="w-100" /></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center pb-md-5 pt-md-5 mt-5 text-start">
                                <div className="col-12 col-md-12 col-lg-9">
                                    <div className="mb-4">
                                        <div className="row justify-content-center text-start">
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <button className="btn text-center" onClick={e => setDataS(1)}>
                                                    <img src={img5} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Objectives</h4>
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <button className="btn text-center" onClick={e => setDataS(2)}>
                                                    <img src={img6} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Participants</h4>
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <a href="https://seameoseps.org/service/Programme21-02-2025.pdf" target={"_blank"} className="btn text-center">
                                                    <img src={img7} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Programme</h4>
                                                </a>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <a href="https://seameoseps.org/service/SEP-ED_Concept_Note.pdf" target={"_blank"} className="btn text-center">
                                                    <img src={img8} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Document</h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-none d-md-block">
                                            <div className="row text-start" style={{ marginBottom: "-20px" }}>
                                                <div className="col-12 col-md-3 col-lg-3 text-center">
                                                    {dataS === 1 && <img src={sornUp} className="" style={{ width: "50px", filter: "brightness(0) saturate(100%) invert(91%) sepia(55%) saturate(6319%) hue-rotate(315deg) brightness(109%) contrast(103%)" }} />}
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3 text-center">
                                                    {dataS === 2 && <img src={sornUp} className="" style={{ width: "50px", filter: "brightness(0) saturate(100%) invert(91%) sepia(55%) saturate(6319%) hue-rotate(315deg) brightness(109%) contrast(103%)" }} />}
                                                </div>
                                            </div>
                                        </div>
                                        {dataS === 1 ?
                                            <div className="p-4" style={{ background: "#FFDE59", borderRadius: "15px" }}>
                                                <ol>
                                                    <li>Enhance understanding of net zero and carbon neutral concepts, with a focus on how these    principles can be integrated into the education sector to support the achievement of these goals.</li>
                                                    <li>Facilitate the exchange of ideas, successful strategies and case studies on how schools and educational institutions have adopted net-zero and carbon-neutral policies and practices.</li>
                                                    <li>Encourage a sustainability-oriented mindset and values such as Moderation, Prudence and Reasonableness with a focus on the ethical and long-term implications of achieving net-zero and carbon-neutral goals in education.</li>
                                                    <li>Create a supportive community of educators for long-term alliance in sustainability through education.</li>
                                                </ol>
                                            </div>
                                            : dataS === 2 ?

                                                <div className="p-4" style={{ background: "#FFDE59", borderRadius: "15px" }}>
                                                    22 Mid-level government officials from 11 SEAMEO Member Countries<br /><br />

                                                    Each member country’s Ministry of Education nominates two delegates who are involved in both policy and practice related to sustainable development in general, and specifically to net-zero and carbon neutrality. The first delegate should be from the Education Ministry’s central office, while it is preferred that the second delegate should be invited from a relevant government agency (e.g. natural resources, energy, industry, agriculture) but may also come from the Ministry of Education. The Ministry of Education has discretion over this decision.<br /><br />

                                                    The cost of participation of the 22 participants from 11 SEAMEO Member Countries will be supported by SEAMEO SEPS including return economy air tickets, accommodation, meals, and local transportation in Thailand.

                                                </div>

                                                : null}
                                    </div>
                                    <div className="row justify-content-center pb-md-5 pt-md-5 mt-5 text-start">
                                        <div className="col-12 col-md-8 col-lg-8">
                                            <div className="d-none d-md-block">
                                                <iframe width="100%" height="515" src="https://www.youtube.com/embed/m_OW5psWlkc?si=FLgnE5-lQ2Spz_uf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen ></iframe>
                                            </div>
                                            <div className="d-block d-md-none">
                                                <iframe width="100%" height="210" src="https://www.youtube.com/embed/m_OW5psWlkc?si=FLgnE5-lQ2Spz_uf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen ></iframe>
                                            </div>
                                        </div>
                                    </div>


                                    {dataOne.news && dataOne.news.length ?
                                        <div>
                                            <div className="topic mb-4 mt-5">News</div>
                                            <div className="row">
                                                {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                                {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                    <div
                                                        className="col-12 col-md-4 col-lg-4"
                                                        key={index}
                                                    >
                                                        <Link
                                                            to={"/newsandgallery/news/" + val.newsId}
                                                            className="a-news pb-4 mb-4 mb-md-5"
                                                        >
                                                            <div className="row text-start">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_IMG_LINK +
                                                                                "/uploads/news/" +
                                                                                val.newsId +
                                                                                "/" +
                                                                                val.banner
                                                                            }
                                                                            className="w-100 rounded"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className="font-14 mt-2">
                                                                        {moment(val.dateActive).format("D MMMM Y")}
                                                                    </div>

                                                                    <div className="news-topic mt-3 mt-md-0"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: val.topic,
                                                                        }}
                                                                    />
                                                                    {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                                                    <div className="mt-4">
                                                                        <LinesEllipsis
                                                                            text={val.subTopic}
                                                                            maxLine="3"
                                                                            ellipsis="..."
                                                                            trimRight
                                                                            basedOn="letters"
                                                                        />
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <a style={{ color: "#e6c12f" }}>
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        : null}

                                    <div className="topic mb-3">Gallery</div>
                                    <div>
                                        <div className="d-flex gap-1 gap-md-2">
                                            <div onClick={() =>setModalgallery({view: true,gallery: mainData.photo,dataUrl: mainData.photo[0],active: 0})}><img src={g1} className="w-100 cursor-pointer" /></div>
                                            <div onClick={() =>setModalgallery({view: true,gallery: mainData.photo,dataUrl: mainData.photo[1],active: 1})}><img src={g2} className="w-100 cursor-pointer" /></div>
                                        </div>
                                    </div>
                                    <div className="mt-1 mt-md-2 mb-5">
                                        <div className="d-flex gap-1 gap-md-2">
                                            <div onClick={() =>setModalgallery({view: true,gallery: mainData.photo,dataUrl: mainData.photo[2],active: 2})}><img src={g3} className="w-100 cursor-pointer" /></div>
                                            <div onClick={() =>setModalgallery({view: true,gallery: mainData.photo,dataUrl: mainData.photo[3],active: 3})}><img src={g4} className="w-100 cursor-pointer" /></div>
                                            <div className="cursor-pointer position-relative" onClick={() =>setModalgallery({view: true,gallery: mainData.photo,dataUrl: mainData.photo[4],active: 4})}>
                                                <img src={g5} className="w-100" />
                                                <div className="position-absolute absolute-center-center w-100 h-100" style={{ background: "rgba(0,0,0,.4)" }}>
                                                    <h1 className="position-absolute absolute-center-center text-white" >
                                                        +{(mainData.photo).length - 5}
                                                    </h1>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="row px-2 mb-5">
                                        {(mainData.photo).length
                                            ? (mainData.photo).map(
                                                (val, index) => (
                                                    <div
                                                        className="col-4 col-md-4 col-lg-3 p-2"
                                                        key={index}
                                                    >
                                                        <div
                                                            className="gg-box-d"
                                                            style={{
                                                                backgroundImage: `url(${val})`,
                                                            }}
                                                            onClick={() =>
                                                                setModalgallery({
                                                                    view: true,
                                                                    gallery: mainData.photo,
                                                                    dataUrl:val,
                                                                    active: index,
                                                                })
                                                            }
                                                        ></div>
                                                    </div>
                                                )
                                            )
                                            : null}
                                    </div> */}

                                    <div className="topic mb-3">Previous Activities</div>
                                    <div className="mb-5">
                                        <a href="javascript:;" className="btn btn-watch-passtel font-16 px-5 py-2">2024</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="capacity" />
                </div>
            </div>

            {modalgallery.view ? (
                <Modalgallery
                    show={modalgallery.view}
                    onHide={() => setModalgallery({ view: false })}
                    dataUrl={modalgallery.dataUrl}
                    gallery={modalgallery.gallery}
                    active={modalgallery.active}
                />
            ) : null}
        </>
    );
};

export default Main;
