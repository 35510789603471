import React, { useState, useEffect, useRef } from "react";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

import imgBanner from './../../../assets/img/03-2025/banner.jpg';

import img5 from './../../../assets/img/02-2025/5_0.png';
import img6 from './../../../assets/img/02-2025/6_0.png';
import img8 from './../../../assets/img/02-2025/8_0.png';
import img9 from './../../../assets/img/03-2025/9_0.png';
import img10 from './../../../assets/img/03-2025/10_0.png';
import img11 from './../../../assets/img/03-2025/11_0.png';

import sornUp from './../../../assets/img/icon/sorn-up.svg';

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});
    const [dataS, setDataS] = useState(null);

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "grants")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="research" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-50 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="font-18 text-justify content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center py-3 py-md-5 text-start" style={{ background: "#FFDE59" }}>
                                <div className="col-11 col-md-9 col-lg-9">
                                    <div>
                                        <div><img src={imgBanner} className="w-100" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center pb-md-5 pt-md-5 mt-5 text-start">
                                <div className="col-11 col-md-9 col-lg-9">
                                    <div className="">
                                        <div className="row justify-content-center text-start">
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <button className="btn text-center" onClick={e => setDataS(1)}>
                                                    <img src={img9} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Theme & Research Areas </h4>
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <a href="https://seameoseps.org/service/SEAMEOSEPS_ResearchGrants_ExpectedOutcomes.pdf" target={"_blank"} className="btn text-center">
                                                    <img src={img5} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Expected Outcomes & Outputs </h4>
                                                </a>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">

                                                <button className="btn text-center" onClick={e => setDataS(3)}>
                                                    <img src={img6} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Who Can Apply</h4>
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <button className="btn text-center" onClick={e => setDataS(4)}>
                                                    <img src={img10} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Benefits</h4>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-none d-md-block">
                                            <div className="row text-start" style={{ marginBottom: "-20px" }}>
                                                <div className="col-12 col-md-3 col-lg-3 text-center">
                                                    {dataS === 1 && <img src={sornUp} className="" style={{ width: "50px", filter: "brightness(0) saturate(100%) invert(91%) sepia(55%) saturate(6319%) hue-rotate(315deg) brightness(109%) contrast(103%)" }} />}
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3 text-center"></div>
                                                <div className="col-12 col-md-3 col-lg-3 text-center">
                                                    {dataS === 3 && <img src={sornUp} className="" style={{ width: "50px", filter: "brightness(0) saturate(100%) invert(91%) sepia(55%) saturate(6319%) hue-rotate(315deg) brightness(109%) contrast(103%)" }} />}
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3 text-center">
                                                    {dataS === 4 && <img src={sornUp} className="" style={{ width: "50px", filter: "brightness(0) saturate(100%) invert(91%) sepia(55%) saturate(6319%) hue-rotate(315deg) brightness(109%) contrast(103%)" }} />}
                                                </div>
                                            </div>
                                        </div>
                                        {dataS === 1 ?
                                            <div className="p-4 text-justify" style={{ background: "#FFDE59", borderRadius: "15px" }}>

                                                <div><b>General Theme: SEP and Sustainability</b></div>
                                                <div>
                                                    <p>The focus of the SEAMEO SEPS Research Grants FY 2024/2025 – FY 2025/2026 is to encourage research work that looks into the application of SEP in addressing sustainable development challenges across Southeast Asia. Research should explore how SEP can serve as a foundational context, a baseline for comparative studies, or a practical tool for linking various sectors, such as education, environment, culture, technology, and economic resilience, to sustainable development goals.</p>
                                                    <p>It is essential that SEP is included in the research either as the central theme or integrated into the study through its application to real-world problems. Whether focusing on theory, practice, or both, proposals should demonstrate how SEP principles contribute to sustainability and align with regional development objectives, such as the UN 2030 Agenda for Sustainable Development.</p>

                                                </div>
                                                <div><b>Research Areas</b></div>
                                                <ul>
                                                    <li>Enhance understanding of net zero and carbon neutral concepts, with a focus on how these    principles can be integrated into the education sector to support the achievement of these goals.</li>
                                                    <li>Facilitate the exchange of ideas, successful strategies and case studies on how schools and educational institutions have adopted net-zero and carbon-neutral policies and practices.</li>
                                                    <li>Encourage a sustainability-oriented mindset and values such as Moderation, Prudence and Reasonableness with a focus on the ethical and long-term implications of achieving net-zero and carbon-neutral goals in education.</li>
                                                    <li>Create a supportive community of educators for long-term alliance in sustainability through education.</li>
                                                </ul>
                                            </div>
                                            : dataS === 3 ?

                                                <div className="p-4 text-justify" style={{ background: "#FFDE59", borderRadius: "15px" }}>
                                                    <div><b>Who Can Apply?</b></div>
                                                    <ul>
                                                        <li>A national of one of the SEAMEO Member Countries</li>
                                                        <li>Between 18 and 55 years of age at the time of application</li>
                                                        <li>Proficient in the English language</li>
                                                        <li>Not employed/contracted by any of SEAMEO Centres and Network, or by SEAMEO Secretariat</li>
                                                        <li>Not a previous recipient of any SEAMEO grant for the past 3 years</li>
                                                    </ul>
                                                </div>

                                                : dataS === 4 ?

                                                    <div className="p-4 text-justify" style={{ background: "#FFDE59", borderRadius: "15px" }}>
                                                        <div><b>Benefits of Grant Recipients</b></div>
                                                        <ul>
                                                            <li>USD 4,000 research funding for meaningful studies on SEP and sustainable development</li>
                                                            <li>Opportunities to present findings at SEAMEO SEPS and other events</li>
                                                            <li>Access to a network of practitioners and scholars dedicated to SEP and sustainability</li>
                                                        </ul>
                                                    </div>

                                                    : null}
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-center py-5 mt-5 text-start" style={{ background: "#023376" }}>
                                <div className="col-11 col-md-11 col-lg-10">
                                    <div className="text-center text-white h2 mb-4"><b>Important Dates</b></div>
                                    <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                                        <div className="timeline-step">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>25 NOV 2024</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Call for Submission of Research Proposals</p>
                                            </div>
                                        </div>
                                        <div className="timeline-step">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>28 FEB 2025</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Submission Deadline</p>
                                            </div>
                                        </div>
                                        <div className="timeline-step">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>1 MAR - 30 APR 2025</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Entry Evaluation, shortlisting and selection</p>
                                            </div>
                                        </div>
                                        <div className="timeline-step">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>30 APR 2025</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Announcement of Grant Recipients</p>
                                            </div>
                                        </div>
                                        <div className="timeline-step mb-0">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>1 MAY 2025</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Start of Research Implementation</p>
                                            </div>
                                        </div>
                                        <div className="timeline-step mb-0">
                                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1" style={{ color: "#FEDE58" }}><b>TBC</b></p>
                                                <p className="mb-0 mb-lg-0" style={{ fontSize: "14px", color: "#D7F7FE" }}>Presentation of Findings at SEAMEO SEPS Events</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {dataOne.news && dataOne.news.length ?
                                <div className="row justify-content-center pb-md-5 pt-md-5 mt-5 text-start">
                                    <div className="col-11 col-md-9 col-lg-9">
                                        <div>
                                            <div className="topic mb-4 mt-5">News</div>
                                            <div className="row">
                                                {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                                {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                    <div
                                                        className="col-12 col-md-4 col-lg-4"
                                                        key={index}
                                                    >
                                                        <Link
                                                            to={"/newsandgallery/news/" + val.newsId}
                                                            className="a-news pb-4 mb-4 mb-md-5"
                                                        >
                                                            <div className="row text-start">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_IMG_LINK +
                                                                                "/uploads/news/" +
                                                                                val.newsId +
                                                                                "/" +
                                                                                val.banner
                                                                            }
                                                                            className="w-100 rounded"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className="font-14 mt-2">
                                                                        {moment(val.dateActive).format("D MMMM Y")}
                                                                    </div>

                                                                    <div className="news-topic mt-3 mt-md-0"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: val.topic,
                                                                        }}
                                                                    />
                                                                    {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                                                    <div className="mt-4">
                                                                        <LinesEllipsis
                                                                            text={val.subTopic}
                                                                            maxLine="3"
                                                                            ellipsis="..."
                                                                            trimRight
                                                                            basedOn="letters"
                                                                        />
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <a style={{ color: "#e6c12f" }}>
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="row justify-content-center pb-md-5 pt-md-5 mt-5 text-start">
                                <div className="col-11 col-md-9 col-lg-9">
                                    <div className="mb-4 d-none d-md-block">
                                        <div className="row justify-content-center text-start">
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <button className="btn text-center">
                                                    <img src={img8} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Download</h4>
                                                    <div className="d-md-flex gap-1">
                                                        <a className="btn btn-outline-passtel font-14 py-1 px-2 text-dark mb-2" href="https://drive.google.com/file/d/19ualf8WzTIkkuBvNaYcZMI3iGuqoUwDA/view?usp=sharing" target="_blank">
                                                            Guidelines
                                                        </a>
                                                        <a className="btn btn-outline-passtel font-14 py-1 px-2 text-dark mb-2" href="https://docs.google.com/document/d/17vTCop9g72E-u1ub9shCMsh1C6kgBAqq/edit?usp=drive_link&ouid=100593425839943638129&rtpof=true&sd=true" target="_blank">
                                                            Application Form
                                                        </a>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <a href="https://seameoseps.org/service/Programme21-02-2025.pdf" className="d-block btn text-center" target={"_blank"}>
                                                    <img src={img11} className="w-75 px-4" />
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Contact Person</h4>
                                                    <div class="text-start font-14">
                                                        Ms Abigail Lanceta <br />
                                                        Email: <a href="mailto:l.abigail@seameoseps.org">l.abigail@seameoseps.org</a> <br />
                                                        cc research@seameoseps.org

                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5 d-block d-md-none">
                                        <div className="row justify-content-center text-start">
                                            <div className="col-6 col-md-3 col-lg-3">
                                                <div className="text-center">
                                                    <img src={img8} className="w-75 px-4" />                                                   
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="text-center">                                                    
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Download</h4>
                                                    <div className="">
                                                        <a className="btn btn-outline-passtel font-14 py-1 px-2 text-dark mb-2 me-2" href="https://drive.google.com/file/d/19ualf8WzTIkkuBvNaYcZMI3iGuqoUwDA/view?usp=sharing" target="_blank">
                                                            Guidelines
                                                        </a>
                                                        <a className="btn btn-outline-passtel font-14 py-1 px-2 text-dark mb-2" href="https://docs.google.com/document/d/17vTCop9g72E-u1ub9shCMsh1C6kgBAqq/edit?usp=drive_link&ouid=100593425839943638129&rtpof=true&sd=true" target="_blank">
                                                            Application Form
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center text-start my-3">
                                            <div className="col-6 col-md-3 col-lg-3 text-center mt-3">
                                            <img src={img11} className="w-75 px-4" />
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3">
                                                <div className="d-block btn text-center" target={"_blank"}>
                                                    <h4 className="weight-700 mt-3 h4-respon" style={{}}>Contact Person</h4>
                                                    <div class="text-start font-14">
                                                        Ms Abigail Lanceta <br />
                                                        Email: <a href="mailto:l.abigail@seameoseps.org">l.abigail@seameoseps.org</a> <br />
                                                        cc research@seameoseps.org

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="research" />
                </div>
            </div>
        </>
    );
};

export default Main;
